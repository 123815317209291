<template>
  <div v-click-outside="onClickOutside" class="watchlist_dialog add_dialog" v-if="showIt">

    <p class="watchlist_dialog__header">{{ $t("genios.watchlist.addTitle") }}</p>
    <div class="info">{{ $t("genios.watchlist.addDescription") }}</div>

    <div v-if="noDocumentsSelected"
         class="watchlist_dialog__errorBox">
      {{ $t("genios.watchlist.noSelection") }}
    </div>

    <div v-else>
      <div class="watchlistCollection">
        <spinner v-if="watchlistCollectionCheckBox.length === 0 && errorCode === null"/>

        <div class="watchlistCollection__scrollable">
          <checkbox-form-element
              v-for="watchlist in watchlistCollectionCheckBox"
              :key="watchlist.id"
              :element="{...watchlist, fieldLabel: `${watchlist.name} (${watchlist.numberOfBookmarks})`}"
              :passed-value="isSelected(watchlist.id)"
              @modified="toggleSelection(watchlist.id)"/>
        </div>
      </div>
    </div>

    <div v-if="errorCode !== null" class="watchlist_dialog__errorBox">
      {{ $t(errorCode) }}
    </div>

    <div class="divider"></div>

    <div class="watchlist_dialog__button_line">
      <Button
          name="close"
          variant="secondary"
          @click="hideDialog"
          :text="$t('genios.close')"
          :toggle-text="false"
      />
      <Button
          name="save"
          variant="primary"
          @click="saveElements"
          :text="$t('genios.watchlist.add')"
          :disabled="noDocumentsSelected"
          :toggle-text="false"
      />
    </div>

  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import vSelect from "vue-select";

import Spinner from "../styled/Spinner.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";

import {addBookmarks, fetchUsersWatchlistBookmarks} from "../../../functions/fetching";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import store from "../../../functions/store/modules";

export default {
  name: "WatchlistAddComponent",
  components: {Button, Spinner, vSelect, CheckboxFormElement},
  props: {
    sharedState: Object,
    documentIds: Array
  },
  data() {
    return {
      showIt: true,
      watchlistCollectionCheckBox: [],
      selectedItems: [],
      entityId: 'test',
      errorCode: null,
      newSelected: i18n.t("genios.watchlist.selectWatchlist"),
    }
  },
  mounted() {
    this.loadUsersWatchlists();
    fetchUsersWatchlistBookmarks();
  },
  directives: {
    clickOutside: vClickOutside?.directive
  },
  computed: {
    noDocumentsSelected() {
      return this.documentIds == null || this.documentIds.length < 1
    },
  },
  watch: {
    newSelected: function (value) {
      const defaultValue = i18n.t("genios.watchlist.selectWatchlist");
      if (value !== defaultValue) {
        this.watchlistCollectionCheckBox.push(value);
        this.toggleSelection(value.id);
        this.newSelected = defaultValue;
      }
    }
  },
  methods: {
    async loadUsersWatchlists() {
      this.watchlistCollectionCheckBox = store.getters['getBookmarkList'] || [];

      if (this.watchlistCollectionCheckBox.length === 0) {
        await store.dispatch('fetchBookmarkList');
        this.watchlistCollectionCheckBox = store.getters['getBookmarkList'] || [];
      }

      if (this.watchlistCollectionCheckBox.length > 0) {
        const activeWatchlists = this.watchlistCollectionCheckBox.filter(watchlist =>
            watchlist.categoryTags && watchlist.categoryTags.includes("Active")
        );
        this.selectedItems = activeWatchlists.map(watchlist => watchlist.id);
      }

      this.errorCode = null;
    },
    onClickOutside() {
      this.hideDialog();
    },

    hideDialog() {
      this.showIt = false;
      this.$emit('closeWidget', true);
    },
    toggleSelection(watchlistId) {
      this.addOrRemove(this.selectedItems, watchlistId);
    },
    isSelected(watchlistId) {
      return this.selectedItems.indexOf(watchlistId) > -1;
    },
    addBookmarkToList(savedBookmarks) {
      const bookmarks = this.sharedState.watchlistDocumentIds;
      if (bookmarks && this.sharedState.loginStatus.loggedIn) {
        for (let i = 0; i < savedBookmarks.length; i++) {
          if (bookmarks.indexOf(savedBookmarks[i]) === -1) {
            bookmarks.push(savedBookmarks[i]);
          }
        }
        sessionStorage.setItem('watchlistDocumentIds', JSON.stringify(bookmarks));
      }
    },

    async saveElements() {
      if (this.selectedItems.length < 1) {
        this.errorCode = "genios.watchlist.noWatchlistSelected";
        return
      }

      await addBookmarks(this.selectedItems, this.documentIds).then((response) => {
        const {data} = response;
        if (data && data.length > 0) {
          const savedBookmarks = data.flatMap(d =>
              d.map(doc => ({
                bookmarkId: doc.id,
                documentId: doc.bookmarkedItem,
              }))
          );
          this.addBookmarkToList(savedBookmarks);
          this.errorCode = null;
          this.$emit('documentsSaved', true);
          this.hideDialog();
        }
        this.watchlistCollectionCheckBox.forEach((watchlist) => {
          const isActive = this.isSelected(watchlist.id);
          store.dispatch('activateBookmarkList', {
            id: watchlist.id,
            isActive: isActive,
          })
        });
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorSave";
        console.error("Save-Watchlistentries " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      });
      await store.dispatch('fetchBookmarkList');
      const bookmarkList = store.getters['getBookmarkList'];
      store.commit('setActiveWatchlistIds', bookmarkList.filter(item =>
          item.categoryTags && item.categoryTags.includes('Active')
      ).map((list) => list.id));
      const selectedBookmarkList = store.getters['getSelectedBookmarkList'];
      if (!selectedBookmarkList || selectedBookmarkList.length === 0) {
        await store.dispatch('setInitialActiveWatchlist');
      }
    },
    addOrRemove(array, value) {
      const index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    }

  }
}

</script>


<style src="vue-select/dist/vue-select.css"></style>

<style scoped>
.watchlistCollection {
  padding-top: 20px;
}

.watchlistCollection__scrollable {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
}

.watchlistCollection__scrollable::-webkit-scrollbar {
  width: 6px;
}

.watchlistCollection__scrollable::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  border-radius: 3px;
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.10);
  margin: 15px 0 24px;
}
</style>
