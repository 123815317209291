<template>
  <div class="sidePanel">
    <div class="searchResult__filters fixed">
      <overlay-scrollbars
          v-if="showOverlayScrollbarSection"
          class="sidenav"
          :options="osOptions"
      >
        <template v-if="sizePanel === 'open'">
          <div class="border-line border-line-fix"></div>
          <div class="filters">
            <CheckBoxFilter
                v-for="(value, name) in topFilterParams"
                v-if="shownFilters.includes(name)"
                :key="name"
                :id="name + '_filter'"
                class="filter individual-filter"
                :shared-state="sharedState"
                :title="value.title"
                :search-placeholder="value.searchPlaceholder"
                :type="name"
                :max-number="value.maxNumber"
                aria-label="search results filter"
            >
              <div class="filters__clear" v-show="activeFiltersForCurrentFilterGroup">
                <Button
                    name="reset"
                    :text="$t('genios.input.clear')"
                    variant="secondary"
                    type="reset"
                    :toggle-text="false"
                    @click.stop="clearFilters(name)"
                />
              </div>
            </CheckBoxFilter>
          </div>
          <div class="sidenav__header">
            <div class="sidenav__text" :style="{ fontWeight: displaySearch ? 'bold' : '400' }">
              <p>{{ $t('genios.advancedSearch.name') }}</p>
            </div>
            <div class="sidenav__toggle">
              <Toggle :is-selected="displaySearch" :id="$t('genios.advancedSearch.name')" @update-toggle="updateAdvancedSearch"/>
            </div>
          </div>
          <div class="border-line"></div>
          <div class="filters" v-if="displaySearch">
            <AdvancedSearchComponent
                :sidePanelPosition="this.sizePanel"
                :source-filters="sourceFilters"
                :category-filters="categoryFilters"
                :advanced-search-params="advancedSearchParams"
                :key="reloadKey"
                @empty-search-masks="(disable)=>this.disableAdvancedSearch = disable"
                @clear-advanced-search="clearAdvancedSearch()"
            />
          </div>
          <div class="border-line"></div>
          <div class="sidenav__header">
            <div class="sidenav__text" :style="{ fontWeight: displayFilters ? 'bold' : '400' }">
              <p>{{ $t("genios.filter") + ' ('+getActiveNavigationName()+')'}}</p>
            </div>
            <div class="sidenav__toggle">
              <Toggle :is-selected="displayFilters" :id="$t('genios.filter') + ' ('+getActiveNavigationName()+')'" @update-toggle="updateToggle($event)"/>
            </div>
          </div>
          <div class="border-line"></div>
          <div class="filters" v-if="displayFilters">
            <DateFilter
                :title="dateRangeFilterParams.title"
                :shared-state="sharedState"
            />
            <CheckBoxFilter
                v-for="(value, name) in filterParams"
                v-if="shownFilters.includes(name)"
                :key="name"
                :id="name + '_filter'"
                class="filter"
                :shared-state="sharedState"
                :title="value.title"
                :search-placeholder="value.searchPlaceholder"
                :type="name"
                :max-number="value.maxNumber"
                aria-label="search results filter"
            >
            </CheckBoxFilter>
            <div class="filter__footer" v-show="hasActiveFilters">
              <div class="filters__clear">
                <Button
                    name="reset"
                    :text="$t('genios.input.clear')"
                    variant="secondary"
                    type="reset"
                    :toggle-text="false"
                    @click.stop="clearFilters()"
                />
              </div>
            </div>
          </div>
        </template>
      </overlay-scrollbars>
    </div>
    <VerticalDraggableDivider
        :size-panel="sizePanel"
        @toggle-sidebar="togglePanel($event)"
        @divider-position="adjustDividedPanels($event)"
    />
  </div>
</template>


<script>

import CheckBoxFilter from "./Filters/CheckBoxFilter.vue";
import AdvancedSearchComponent from "./AdvancedSearch/AdvancedSearchComponent.vue";
import i18n from "./plugins/Translations.vue";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';
import DateFilter from "./Filters/DateFilter.vue";
import Button from "./styled/Button.vue";
import {buildPath} from "../../functions/utils/url_utils";
import axios from "axios";
import {replaceSearchResult} from "../../functions/replacing";
import {getAuthCookieCacheBuster} from "../../functions/utils/cookie_utils";
import Toggle from "./styled/Toggle.vue";
import VerticalDraggableDivider from "./VerticalDraggableDivider.vue";
import getActiveNavigationName from "../../functions/utils/get_active_navigation_name";
import store from "../../functions/store/modules";

export default {
  name: "SidePanel",
  components: {
    VerticalDraggableDivider,
    Toggle,
    Button,
    DateFilter,
    CheckBoxFilter,
    AdvancedSearchComponent,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    sharedState: Object,
  },
  data() {
    return {
      mode: localStorage.getItem('mode'),
      sizePanel: this.initialSizePanel(),
      dateRangeFilterParams: {
        title: i18n.t("genios.dateRange")
      },
      osOptions: {
        clipAlways: false,
        scrollbars: {autoHide: 'move', clickScrolling: true},
        callbacks: {
          onScroll: this.onScroll
        }
      },
      topFilterParams: {
        category: {
          title: getActiveNavigationName(),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 40
        }
      },
      filterParams: {
        source: {
          title: i18n.t("genios.sources"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 15
        },
        company: {
          title: i18n.t("genios.companies"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        person: {
          title: i18n.t("genios.people"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        author: {
          title: i18n.t("genios.authors"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        instructor: {
          title: i18n.t("genios.instructors"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        industry: {
          title: i18n.t("genios.industries"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        topic: {
          title: i18n.t("genios.themes"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        },
        region: {
          title: i18n.t("genios.regions"),
          searchPlaceholder: i18n.t("genios.filterBy"),
          maxNumber: 10
        }
      },
      reloadKey: 0,
      isHeaderSticky: false,
      disableAdvancedSearch: false,
      showOverlayScrollbarSection: false
    };

  },
  computed: {
    activeFilters: function () {
      return this.sharedState.activeFilters || {};
    },
    sourceFilters: function () {
      return this.sharedState.activeFilters.source;
    },
    userSettings: function () {
      return store.getters.getUserInteractionSearchFilterDisplaySettings;
    },
    displayFilters: function () {
      return this.userSettings?.filtersOpened || false;
    },
    displaySearch: function () {
      return this.userSettings?.advancedSearchOpened || false;
    },
    categoryFilters: function () {
      return this.activeFilters.category;
    },
    advancedSearchParams: function () {
      return this.sharedState.advancedSearchParams;
    },
    urlSecondLevel: function () {
      return this.sharedState.urlSecondLevel;
    },
    filtersAvailable: function () {
      return this.sharedState.filtersAvailable[this.urlSecondLevel] || [];
    },
    zFiltersToShow: function () {
      if (!this.urlSecondLevel) {
        // fixed set of filters for top level navigation
        return ["industry", "company", "person", "region", "topic"];
      }
      return this.filtersAvailable;
    },
    shownFilters: function () {
      let filtersToShow = [];
      if (this.categoryFilters || this.urlSecondLevel) {
        filtersToShow.push("category");
      }
      filtersToShow.push("source");
      for (let filterType of ["industry", "company", "theme", "region", "topic", "person", "author", "instructor"]) {
        let filterValues = this.activeFilters[filterType];
        if ((Array.isArray(filterValues) && !!filterValues.length)
            ||
            this.zFiltersToShow.includes(filterType)) {
          filtersToShow.push(filterType);
        }
      }
      return filtersToShow;
    },
    hasActiveFilters: function () {
      const currentActiveFilters = this.extractActiveFiltersForTopFilters();
      let filterLists = Object.values(currentActiveFilters);
      for (let filterList of filterLists) {
        if (filterList.length > 0) {
          return true;
        }
      }
      return false;
    },
    activeFiltersForCurrentFilterGroup: function () {
      return this.categoryFilters?.length > 0;
    },
  },
  async mounted() {
    //This is only because overlayscrollbars take ages to initialise
    this.showOverlayScrollbarSection = true;
    this.$nextTick(() => {
      this.getFiltersAvailable();
    });
  },
  methods: {
    getActiveNavigationName,
    extractActiveFiltersForTopFilters: function () {
      const topFilterKeys = Object.keys(this.topFilterParams);

      return Object.keys(this.activeFilters).reduce((acc, key) => {
        if (!topFilterKeys.includes(key)) {
          acc[key] = this.activeFilters[key];
        }
        return acc;
      }, {});
    },
    clearAdvancedSearch() {
      window.sharedState.advancedSearchParams = [];
      replaceSearchResult();
    },
    clearFilters(name) {
      if (name) {
        window.sharedState.activeFilters[name] = [];
      } else {
        const currentActiveFilters = this.extractActiveFiltersForTopFilters();
        Object.keys(currentActiveFilters).forEach(key => {
          window.sharedState.activeFilters[key] = [];
        });
      }
      replaceSearchResult();
    },
    updateToggle(event) {
      store.commit('setUserInteractionSearchFilterDisplaySettings',
        {
          ...this.userSettings,
          filtersOpened: event,
          userInteracted: true
      })
    },
    updateAdvancedSearch(event) {
      store.commit('setUserInteractionSearchFilterDisplaySettings',
        {
          ...this.userSettings,
          advancedSearchOpened: event,
          userInteracted: true
      })
    },
    getFiltersAvailable() {
      let filtersAvailablePath = buildPath('/api/filtersAvailable');
      let urlTopLevelNavigation = this.sharedState.urlTopLevelNavigation;
      let urlSecondLevel = this.sharedState.urlSecondLevel;
      // use auth cookie as cache busting trick for user switching etc.
      const params = {
        auth: getAuthCookieCacheBuster(),
      }

      axios.get(filtersAvailablePath, {params: params}).then(response => {
        if (!!response.data) {
          const filtersAvailable = response.data;
          let newFiltersAvailable = {};
          newFiltersAvailable[urlSecondLevel || urlTopLevelNavigation] = filtersAvailable;
          window.sharedState.filtersAvailable = {
            ...window.sharedState.filtersAvailable,
            ...newFiltersAvailable
          };
          // reportSearch it for reuse
          localStorage.setItem('availableFilters', JSON.stringify(window.sharedState.filtersAvailable));
        }

        // use/access the results
      }).catch(error => {
        console.log(error);
      });
    },
    getStoredState() {
      return sessionStorage.getItem('showPanel');
    },
    setStoredState(value) {
      store.commit('setShowPanel', value);
      sessionStorage.setItem('showPanel', value);
    },
    onScroll() {
      let parent = this.$el.querySelector('.sidenav__header');
      if (!!parent) {
        this.isHeaderSticky = parent.getBoundingClientRect().top <= 158;
      }
    },
    initialSizePanel() {
      let storedValue = this.getStoredState();
      if (!storedValue || !(['open', 'closed'].includes(storedValue))) {
        this.setStoredState(this.defaultByScreenSize());
        storedValue = this.defaultByScreenSize();
      }
      return storedValue;
    },
    togglePanel() {
      let currentSizePanel = this.sizePanel;
      if (currentSizePanel === 'open') {
        this.closeSidePanel();
      } else if (currentSizePanel === 'closed') {
        this.openSidePanel();
      }
    },
    openSidePanel() {
      this.sizePanel = 'open';
      this.setStoredState('open');
      this.rerenderDividedComponents('open');
    },
    closeSidePanel() {
      this.sizePanel = 'closed';
      this.setStoredState('closed');
      this.rerenderDividedComponents('closed');
    },
    rerenderDividedComponents(showPanel) {
      let sidePanel = document.querySelector('.sidePanel');
      const searchResult = document.querySelector('.searchResult');
      const partialSearchResult = document.querySelector('#partialSearchResult');
      if (sidePanel) {
        sidePanel.classList.toggle("display-on-top", showPanel === 'open');
      }
      if (searchResult) {
        searchResult.classList.toggle("background-overlay", showPanel === 'open');
      }
      if (partialSearchResult) {
        partialSearchResult.classList.toggle("background-overlay", showPanel === 'open');
      }
    },
    defaultByScreenSize() {
      return window.innerWidth <= 600 ? 'closed' : 'open';
    },
    adjustDividedPanels(position) {
      let searchResultFilters = document.querySelector('.sidenav');
      if (searchResultFilters && position) {
        searchResultFilters.style.maxWidth = position + 'px';
        searchResultFilters.style.width = position + 'px';
      }
      this.rerenderDividedComponents(this.sizePanel);
    }
  }
};
</script>

<style scoped>
</style>
