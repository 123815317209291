import axios from "axios";
import qs from "qs";
import store from "../store/modules";

import i18n from "../../vue/components/plugins/Translations.vue";

import {closeFullPopupModal, closeTransparentPopupModal} from "../closing";
import {
    setupFullDocumentFunctionality,
    setupMiniPopup,
    setupPreview,
    setupRemoteAgencyDocDownloadButton,
    setupSendEmailFunctionality,
    setupTableOfContent,
    setupVideoFrame
} from "../setups";
import updatePrices from "../updatePrices";
import {buildPath, buildSearchParametersFromCurrentState} from "../utils/url_utils";
import {setInnerHTML} from "../utils/tricks";
import {setupShareButton} from "../components/shareButton";
import {
    setupBackToTopComponent,
    setupDocumentMetadataComponent,
    setupEPaperDownloadButtonComponent,
    setupFidoComponent,
    setupFullDocumentComponent,
    setupMenuNavigationComponent,
    setupPaymentFormComponent,
    setupTableOfContents,
    setupVideoComponent
} from "../setups/vue-components";
import {
    setupAttachmentDownloadHandler,
    setupCompanyNotificationButton,
    setupEbookAttachmentDownloadHandler,
} from "../setups/handlers";
import {setupNotImplementedAlert} from "../temp";
import {getAuthCookieCacheBuster} from "../utils/cookie_utils";
import {watchlistAddAllButtons} from "../components/watchlist";
import {changeHistory} from "../utils/history_utils";
import {checkShowMoreShowLessSections} from "../checks";
import {PaymentMode} from "../components/payment";
import {checkUnzerStatus, getAuthInfo, getChargeInfo} from "../payment/unzerService";
import isIOSDevice from '../utils/isIOSDevice';
import eventBus from "../../eventBus";
import {openFullArticleModal, openFullPopupModal, openQueryPopup} from "../opens";
import {renderVideo} from "../components/videoComponent";
import {
    LimitOrderCodes,
    MediaType,
    noneSearchPurpose,
    ResearchPurposeOrderCodes,
    SearchPurposeSplit
} from "../consts";
import {getDocumentPricesAsync} from "../services";
import {formatDate} from "../utils/date_utils";

export const fetchTableOfContents = async function (sourceShortcut, tableOfContentsOrText, selectedDocId, issueId) {
    if (tableOfContentsOrText.getAttribute("hasTableOfContents") === "1") {
        setupTableOfContents(selectedDocId, sourceShortcut, issueId, tableOfContentsOrText.getAttribute("useDateForTableOfContents"))
    }
};

export const getPaymentInfo = async () => {
    const paymentData = JSON.parse(sessionStorage.getItem('paymentId')) || null;
    const params = {};
    if (paymentData?.paymentId) {
        const data = await checkUnzerStatus(paymentData.paymentId);

        let paymentInfo;
        if (!data || data.isError) {
            sessionStorage.removeItem('paymentId');
            return params;
        }
        if (data?.state?.name && data?.state?.name !== "canceled") {

            if (data.state.name === "completed")
                paymentInfo = await getChargeInfo(data.id);
            else
                paymentInfo = await getAuthInfo(data.orderId);

            if (paymentInfo) {
                params.orderDataId = paymentInfo.paymentReference;
                params.paymentId = data.id;
                params.basketId = data.resources.basketId;
                params.state = data.state.name;
                params.isSuccess = paymentInfo.isSuccess;
                params.merchantMessage = paymentInfo?.message?.merchant || paymentInfo?.errors[0]?.merchantMessage;
                params.customerMessage = paymentInfo?.message?.customer || paymentInfo?.errors[0]?.customerMessage;
                params.isUnzerError = paymentInfo.isError;
            } else {
                sessionStorage.removeItem('paymentId');
            }
        }
    }
    return params;
};

export const fetchAndInsertDocument = async function (documentId, getNextFunction, getPreviousFunction, pushHistory = true, recaptchaToken, bodyParams) {
    if (window.sharedState.page !== "document") {
        changeHistory({ document: documentId }, pushHistory);
    }

    let docBody = document.querySelector("#modal_content");

    const urlParams = new URLSearchParams(window.location.search);

    if (window.sharedState.page === "document") {
        docBody = document.querySelector(".document__container").parentNode;
    }

    let documentDiv = docBody.querySelector(".document_and_metadata_container");
    if (!documentDiv) {
        documentDiv = docBody;
    }
    if (!!documentDiv) {
        documentDiv.innerHTML = "<div class='loading' style='width:100%'><div class='loader'></div></div>";
    }
    let fetchUrl = '/document/' + documentId + '?embed=true&withButtons=true';
    let requestBody = {};
    if (!!recaptchaToken) {
        requestBody['gRecaptchaResponse'] = recaptchaToken;
    }

    const paymentInfo = await getPaymentInfo();

    let merged = { ...requestBody, ...bodyParams, ...paymentInfo };
    let { searchPurpose, searchPurpose1, searchPurpose2 } = bodyParams;
    if (searchPurpose1 && searchPurpose2) {
        merged = { ...merged, searchPurpose1, searchPurpose2 };
    } else if (searchPurpose) {
        merged = { ...merged, searchPurpose };
    } else {
        const selectedSearchPurposeId = await store.getters.getSelectedSearchPurposeId;

        if (selectedSearchPurposeId) {
            const searchPurposeList = await store.getters.getSearchPurposeList;
            const selectedSearchPurpose = searchPurposeList.find(item => item.id === selectedSearchPurposeId);

            let storedSearchPurpose = selectedSearchPurposeId === noneSearchPurpose.id
                ? noneSearchPurpose.id
                : selectedSearchPurpose?.bookmarkedItem;

            if (storedSearchPurpose?.includes(SearchPurposeSplit.sign)) {
                [searchPurpose1, searchPurpose2] = storedSearchPurpose.split(SearchPurposeSplit.sign);
                merged = { ...merged, searchPurpose1, searchPurpose2 };
            } else {
                searchPurpose = storedSearchPurpose;
            }
        }
        if (searchPurpose) {
            merged = { ...merged, searchPurpose };
        }
    }
    let { data } = await axios.post(fetchUrl, merged);

    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');

    const paymentComponent = doc.querySelector('payment-component');
    const hasDoublePurposes = searchPurpose1 && searchPurpose2;

    if (paymentComponent && (searchPurpose || hasDoublePurposes)) {
        let orderResponse = paymentComponent.getAttribute(':order-response');

        orderResponse = orderResponse.replace(/&quot;/g, '"');

        const orderResponseObject = JSON.parse(orderResponse);
        const orderDataId = orderResponseObject.orderDataId;
        if (orderDataId &&
            (!ResearchPurposeOrderCodes.includes(orderResponseObject.code) && !LimitOrderCodes.includes(orderResponseObject.code))) {
            merged = {...merged, orderDataId};
        }

        data = (await axios.post(fetchUrl, merged)).data;
    }

    if (window.sharedState.page !== "document") {
        data = data + document.querySelector(".footer").outerHTML;
    }

    setInnerHTML(docBody, data);

    //check on any payment model
    if (window.sharedState.page !== "document") {
        await setupPaymentFormComponent(null, documentId, PaymentMode.fullDocument, null, {...paymentInfo});
    }

    const isPaymentStarted = document.querySelector(".payment") || null
    if (!isPaymentStarted) {
        openFullPopupModal(() => {
        })
    }

    const closeLinks = document.querySelectorAll('[js-handler*="closeLinkClickHandler"]');
    closeLinks.forEach(link => {
        const closeLinkClickHandler = function (e) {
            e.preventDefault();
            eventBus.$emit("destroyFIDO");
            closeFullPopupModal(true);
        };
        link.removeEventListener('click', closeLinkClickHandler);
        link.addEventListener('click', closeLinkClickHandler);
    });

    getNextFunction(documentId).then(nextDocumentId => {
        let nextButton = document.querySelector('.document__navigation .right');
        if (!!nextButton) {
            if (!nextDocumentId) {
                nextButton.style.visibility = "hidden";
            }

            const nextButtonClickHandler = function () {
                changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
                eventBus.$emit("destroyFIDO");

                insertLoader();
                openFullArticleModal(nextDocumentId, true, true);
            };
            nextButton.removeEventListener('click', nextButtonClickHandler);
            nextButton.addEventListener('click', nextButtonClickHandler);
        }
    });

    getPreviousFunction(documentId).then(previousDocumentId => {
        let previousButton = document.querySelector('.document__navigation .left');
        if (!!previousButton) {
            if (!previousDocumentId) {
                previousButton.style.visibility = "hidden";
            }

            const previousButtonClickHandler = function () {
                changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
                eventBus.$emit("destroyFIDO");

                insertLoader();
                openFullArticleModal(previousDocumentId, true, true);
            };
            previousButton.removeEventListener('click', previousButtonClickHandler);
            previousButton.addEventListener('click', previousButtonClickHandler);
        }
    });

    function insertLoader() {
        let docBody = document.querySelector("#modal_content");

        if (window.sharedState.page === "document") {
            docBody = document.querySelector(".document__container").parentNode;
        }

        let documentDiv = docBody.querySelector(".document_and_metadata_container");
        if (!documentDiv) {
            documentDiv = docBody;
        }
        if (!!documentDiv) {
            documentDiv.innerHTML = "<div class='loading' style='width:100%'><div class='loader'></div></div>";
        }
    }

    checkShowMoreShowLessSections(docBody);

    const tableOfContentsOrText = docBody.querySelector(".table_of_contents_or_text");
    if (!!tableOfContentsOrText) {
        const sourceShortcut = tableOfContentsOrText.getAttribute("sourceShortcut");
        const issueId = tableOfContentsOrText.getAttribute("issueId");
        const tableOfContentsContainer = docBody.querySelector(".table_of_contents");
        if (!tableOfContentsContainer) {
            await fetchTableOfContents(sourceShortcut, tableOfContentsOrText, documentId, issueId);
        }
    }

    setupFidoComponent();

    setupMiniPopup(docBody);
    setupEPaperDownloadButtonComponent(docBody);
    setupRemoteAgencyDocDownloadButton(docBody);

    setupNotImplementedAlert(document.querySelector('.document__container'));

    setupAttachmentDownloadHandler(docBody);
    setupEbookAttachmentDownloadHandler(docBody);
    updatePrices(docBody);
    setupSendEmailFunctionality(docBody, documentId);
    watchlistAddAllButtons();
    setupShareButton(docBody);
    openQueryPopup(docBody);
    setupVideoComponent(docBody);
    setupDocumentMetadataComponent(docBody);

    const queryPopupId = urlParams.get('queryPopupId');
    await setupFullDocumentComponent(queryPopupId);

    const videoId = urlParams.get("video");
    await setupVideoFrame({videoId, documentId, type: "-1000"});

    setupCompanyNotificationButton(document.querySelector('#company_notification_button'));

    if (window.sharedState.page === "document") {
        window.location.reload();
    }
};

export const fetchAndInsertDocumentInPreview = async function (documentId, recaptchaToken, callback, bodyParams = {}) {

    function addShareFunctionality(documentId) {
        let content = document.getElementById(documentId + "__content") || null;
        let shareIcon = null;
        if (content)
            shareIcon = content.querySelector('[js-handler*="articleContentShare"]');
        if (shareIcon) {
            shareIcon.classList.remove("disabled");
            setupShareButton(shareIcon.parentElement);
        }
    }

    const defaultSuccessCallBack = function (newDocBody, error) {

        const urlParams = new URLSearchParams(window.location.search);
        let currentDocumentSection = document.querySelector("#" + documentId + ".media_preview");

        //this stuff needed only for browse page for videos that need to be paid
        if (!currentDocumentSection) {
            const buyButton = document.querySelector(`#previewDiv .buy_button[data-document-id=${documentId}]`);
            currentDocumentSection = buyButton?.closest(".media_preview");
        }

        const docBody = currentDocumentSection?.querySelector(".media_preview__full_doc__teaser[documentId=" + documentId + "] .teaser__under_title");
        const isNotPreview = currentDocumentSection?.querySelector('.media_preview__full_doc__teaser[documentId=' + documentId + '] .teaser__text');

        if (currentDocumentSection) {

            if (!newDocBody && !!error) {
                newDocBody = "<h2>" + i18n.t("genios.document.error." + error) + "</h2>";
            } else {
                const openInFullModalButton = currentDocumentSection.querySelector(".media_preview__extra_line__full_article_link") || null;
                if (!!openInFullModalButton) {
                    openInFullModalButton.classList.add("downloaded");
                }
            }
            setInnerHTML(docBody, newDocBody);
            const blurredTeaserText = currentDocumentSection.querySelector(".teaser__text__blur") || null;
            if (!!blurredTeaserText) {
                blurredTeaserText.classList.add("hide");
            }
            const teaserButton = currentDocumentSection.querySelector(".teaser__button") || null;
            if (!!teaserButton) {
                teaserButton.classList.add("hide");
            }
            const attachmentButton = currentDocumentSection.querySelector(".teaser__attachment_button") || null;
            if (!!attachmentButton) {
                attachmentButton.classList.remove("hide");
            }
            window.sharedState.lastPaidBundleDocument = documentId;
        }

        setupAttachmentDownloadHandler(docBody);
        setupRemoteAgencyDocDownloadButton(docBody);
        updatePrices(document.body, documentId);
        addShareFunctionality(documentId);
        setupSendEmailFunctionality(docBody, documentId);
        setupFidoComponent(!isNotPreview);

        openQueryPopup(docBody);

        const queryPopupId = urlParams.get('queryPopupId');
        setupFullDocumentComponent(queryPopupId);

        setupVideoComponent(docBody);
        setupDocumentMetadataComponent(docBody);

        const videoId = urlParams.get("video");
        setupVideoFrame({videoId, documentId, type: "-1000"});
    };

    let successCallback = callback || defaultSuccessCallBack;

    let fidoContainer = document.querySelector(".fido_component_container");


    let fidoUid;
    if (!!fidoContainer) {
        fidoUid = fidoContainer.getAttribute("data-fido-id");
    }
    let category = window.sharedState.fidoCategory;

    let fetchUrl = '/api/retrieveDocument/' + documentId;

    let requestBody = {};
    if (!!recaptchaToken) {
        requestBody['gRecaptchaResponse'] = recaptchaToken;
    }

    if (!!fidoUid && !!category && documentId.indexOf("FIDO") === -1) {
        requestBody['masterUid'] = fidoUid;
        requestBody["category"] = category;
    }

    const paymentInfo = await getPaymentInfo();

    try {
        let merged = { ...requestBody, ...bodyParams, ...paymentInfo };
        let { searchPurpose, searchPurpose1, searchPurpose2 } = bodyParams;
        if (searchPurpose1 && searchPurpose2) {
            merged = { ...merged, searchPurpose1, searchPurpose2 };
        } else if (searchPurpose) {
            merged = { ...merged, searchPurpose };
        } else {
            const selectedSearchPurposeId = await store.getters.getSelectedSearchPurposeId;

            if (selectedSearchPurposeId) {
                const searchPurposeList = await store.getters.getSearchPurposeList;
                const selectedSearchPurpose = searchPurposeList.find(item => item.id === selectedSearchPurposeId);

                let storedSearchPurpose = selectedSearchPurposeId === noneSearchPurpose.id
                    ? noneSearchPurpose.id
                    : selectedSearchPurpose?.bookmarkedItem;

                if (storedSearchPurpose?.includes(SearchPurposeSplit.sign)) {
                    [searchPurpose1, searchPurpose2] = storedSearchPurpose.split(SearchPurposeSplit.sign);
                    merged = { ...merged, searchPurpose1, searchPurpose2 };
                } else {
                    searchPurpose = storedSearchPurpose;
                }
            }
            if (searchPurpose) {
                merged = { ...merged, searchPurpose };
            }
        }
        let { data } = await axios.post(fetchUrl, merged);

        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');

        const paymentComponent = doc.querySelector('payment-component');
        const hasDoublePurposes = searchPurpose1 && searchPurpose2;

        if (paymentComponent && (searchPurpose || hasDoublePurposes)) {
            let orderResponse = paymentComponent.getAttribute(':order-response');

            orderResponse = orderResponse.replace(/&quot;/g, '"');

            const orderResponseObject = JSON.parse(orderResponse);
            const orderDataId = orderResponseObject.orderDataId;
            if (orderDataId &&
                (!ResearchPurposeOrderCodes.includes(orderResponseObject.code) && !LimitOrderCodes.includes(orderResponseObject.code))) {
                merged = {...merged, orderDataId};
            }

            data = (await axios.post(fetchUrl, merged)).data;
        }

        if (data?.code)
            await setupPaymentFormComponent(data, documentId, PaymentMode.previewDocument, null, {
                paymentId: paymentInfo.paymentId, orderDataId: paymentInfo.orderDataId
            });
        else {
            const isFIDO = documentId.split("__")[0] === "FIDO";
            const view = qs.parse(window.location.search, {ignoreQueryPrefix: true}).view
            if (isFIDO && view === "grid") {
                // for preview FIDO
                fetchUrl = `/document/${documentId}?embed=true&withButtons=true`;
                const {data: fidoData} = await axios.post(fetchUrl, {});
                successCallback(fidoData, null)?.then(() => {
                    defaultSuccessCallBack(fidoData, data?.errorMessage)
                });
            } else {
                await successCallback(data?.documentHtml, data?.errorMessage);
            }
        }
    } catch (error) {
        console.log("error =>", error);
        console.dir(error);
        return Promise.reject(error);
    }

};

export const fetchAttachment = function (button, orderDataId, token, params) {
    async function handleJsonResponse(button, response) {
        const data = await response.json();

        sessionStorage.setItem('attachmentButton', button.outerHTML);

        window.fetchAttachmentButton = button;
        window.retryOrder = function (orderDataId, token, params) {
            closeTransparentPopupModal();
            fetchAttachment(window.fetchAttachmentButton, orderDataId, token, params);
        };

        const {type: attachmentType} = qs.parse(`?${button.dataset.attachmentDownloadLink.split('?')[1]}`, {ignoreQueryPrefix: true});

        await setupPaymentFormComponent({...data, attachmentType}, button.dataset.legacyDocId, PaymentMode.attachment);
    }

    async function download(response, fileName) {
        let blob = await response.blob();
        if (fileName.endsWith(".pdf")) {
            blob = new Blob([blob], {type: "application/pdf"});
        }
        let url = URL.createObjectURL(blob);
        downloadFileAndOpenInANewTab(fileName, url);
    }

    function downloadFileAndOpenInANewTab(fileName, url) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        if (fileName === null || fileName === '' || fileName === 'undefined') {
            fileName = Date.now() + filePostfix(response.url, fileName);
        }
        link.setAttribute('download', fileName);

        link.setAttribute('target', '_blank');


        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
            // For firefax it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
        }, 100);
    }

    function filePostfix(url, filename) {
        if (filename.indexOf(".") === -1) {
            if (url.toLowerCase().indexOf('epub') !== -1) {
                return ".epub";
            } else if (url.toLowerCase().indexOf('pdf')) {
                return ".pdf";
            }
        }
        return "";
    }

    let downloadLink = button.dataset.attachmentDownloadLink;

    if (orderDataId) {
        downloadLink += ('&orderDataId=' + orderDataId);
    }
    let body = {...params};
    if (token) {
        body['gRecaptchaResponse'] = token;
    }

    fetch(downloadLink, {
        method: 'post',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return handleJsonResponse(button, response);
            } else {
                updatePrices(document.body, button.dataset.documentId);
                return download(response, button.getAttribute("download-display-name"));
            }
        });
};

export const fetchHigherResultListDocumentIds = async function (size = window.sharedState.pageSize) {
    let path = buildPath('/api/searchResult');
    let params = buildSearchParametersFromCurrentState();

    const currentSearchOffset = window.sharedState.documentListOffset || 0;
    const currentListSize = window.sharedState.fullArticleModalList.length;

    params.offset = Number(currentSearchOffset) + Number(currentListSize);
    params.size = size;

    const response = await axios.get(path, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: "repeat"});
        }
    });

    const newDocuments = response.data.documents || [];
    const newIds = newDocuments.map(doc => doc.documentId);
    window.sharedState.fullArticleModalList = window.sharedState.fullArticleModalList.concat(newIds);
    return !!newIds && newIds.length > 0;

};

export const fetchLowerResultListDocumentIds = async function (size = window.sharedState.pageSize) {
    let path = buildPath('/api/searchResult');
    let params = buildSearchParametersFromCurrentState();

    const currentSearchOffset = window.sharedState.documentListOffset || 0;
    if (currentSearchOffset <= 0) {
        return false;
    }
    params.offset = Number(currentSearchOffset) <= size ? 0 : Number(currentSearchOffset) - size;
    params.size = Number(currentSearchOffset) - params.offset;

    const response = await axios.get(path, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: "repeat"});
        }
    });

    const newDocuments = response.data.documents || [];
    const newIds = newDocuments.map(doc => doc.documentId);
    window.sharedState.fullArticleModalList = newIds.concat(window.sharedState.fullArticleModalList);
    window.sharedState.documentListOffset = params.offset;
    return !!newIds && newIds.length > 0;

};

export const fetchFullText = function (documentId) {
    const buyButton = document.querySelector(".buy_button[data-document-id=" + documentId + "]");
    if (!!buyButton) {
        if (buyButton.dataset.paid === "true" || buyButton.dataset.price === "0") {
            const docBody = document.querySelector(".media_preview__full_doc__teaser[documentId=" + documentId + "] .teaser__under_title");
            if (!!docBody && !docBody.dataset.fetched) {
                fetchAndInsertDocumentInPreview(documentId).then(() => {
                });
            }
        } else {
            const teaserTestBluredDiv = document.querySelector(".media_preview__full_doc__teaser[documentId=" + documentId + "] .teaser__text__blur");
            if (teaserTestBluredDiv && !teaserTestBluredDiv.dataset.fetched) {
                axios
                    .get("/api/retrieveFullText/" + documentId)
                    .then(response => {
                        teaserTestBluredDiv.innerHTML = response.data;
                        teaserTestBluredDiv.dataset.fetched = "true";
                        let element = document.querySelector(".media_preview__full_doc__teaser[documentId=" + documentId + "]");
                        if (!!element) {
                            element.style.display = "block";
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
};

export const fetchSearchMask = function (callbackFunction, navigationUrl) {
    let path = "/api/searchMask";
    path = navigationUrl ? path.concat(navigationUrl) : buildPath(path);
    let params = {...window.sharedState.activeFilters};
    axios
        .get(path, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            }
        })
        .then(response => {
            callbackFunction(response.data);
        })
        .catch(error => {
            console.log(error);
        });
};

export const fetchCountsForNavigationItems = function (textIds, withFilters) {
    let path = '/api/countForNavigationItems';
    // use auth cookie as cache busting trick for user switching etc.
    let params = {
        requestText: window.sharedState.queryString,
        textIds: textIds,
        auth: getAuthCookieCacheBuster()
    };
    if (withFilters) {
        if (!!window.sharedState.urlTopLevelNavigation && !!window.sharedState.urlSecondLevel) {
            path = path + "/" + window.sharedState.urlTopLevelNavigation + "/" + window.sharedState.urlSecondLevel;
        }
        const filterParams = buildSearchParametersFromCurrentState();
        params = {...params, ...filterParams};
    }
    axios
        .get(path, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            }
        })
        .then(response => {
            window.sharedState.textIdCountMap = {...window.sharedState.textIdCountMap, ...response.data};
            let categoryFilters = window.sharedState.filters.category;
            for (const filter in categoryFilters) {
                let filterObject = categoryFilters[filter];
                filterObject.count = window.sharedState.textIdCountMap[filterObject.textId];
            }
            const sorted = Object.fromEntries(
                Object.entries(categoryFilters).sort(([, a], [, b]) => b.count - a.count)
            );
            window.sharedState.filters.category = {...sorted}; // force vue update
        })
        .catch(error => {
            console.log(error);
        });
};

function initAfterLoadedData() {
    if (!!window.menuNavigation) {
        window.menuNavigation.$forceUpdate();
        return;
    }
    setupMenuNavigationComponent();
    setupBackToTopComponent();
}

export const fetchNavigationTree = async function (isOnPageLoad = false) {
    try {
        if (!(isOnPageLoad && window.sharedState?.navigationTree?.length)) {
            const {data} = await axios.get('/api/navigationTree');
            window.sharedState.navigationTree = data;
        }
        let textIdsTop = [];
        for (let item of window.sharedState.navigationTree) {
            textIdsTop.push(item.textId);
            if (!!item.nextLevel && item.name === window.sharedState.urlSecondLevel) {
                for (let nextLevelItem of item.nextLevel) {
                    window.sharedState.filters.category[nextLevelItem.name] = {textId: nextLevelItem.textId};
                }
            }
        }
        if (window.sharedState.pathRoot === 'searchResult') {
            fetchCountsForNavigationItems(textIdsTop, false);
        }
    } catch (error) {
        console.log(error);
    } finally {
        initAfterLoadedData();
    }
};

export const fetchUsersWatchlistBookmarks = function () {
    const bookmarks = window.sharedState.watchlistDocumentIds;
    if (!bookmarks && window.sharedState.loginStatus.loggedIn) {
        axios.get("/api/watchlist_bookmarks/all")
            .then(response => {
                window.sharedState.watchlistDocumentIds = response.data.map(item => ({
                    bookmarkId: item.id,
                    documentId: item.bookmarkedItem,
                    itemDate: item.itemDate
                }));
                sessionStorage.setItem('watchlistDocumentIds', JSON.stringify(window.sharedState.watchlistDocumentIds));
            })
            .catch(errors => {
                console.error("Load-Bookmarks " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
            });
    }
};

export const fetchNavigationItems = async () => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const {data} = await axios.get("/api/mygenios/menuItems");
        return data;
    } catch (e) {
        console.log(e);
    }
};

export const fetchDocumentPreview = async function (docId, previewDiv, previewElemToOpen) {
    try {
        let path = '/getDocumentPreview';
        let params = {
            documentId: docId,
            index: 0,
            hasNext: true,
            buy: true,
            viewType: getViewType(previewElemToOpen)
        };
        const {data: previewData} = await axios.get(path, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            }
        });
        let content = previewElemToOpen.querySelector('#' + docId + "__content");
        setInnerHTML(content, previewData);
        const showMoreClickHandler = (event) => {
            event.preventDefault();
            previewElemToOpen.classList.add('full_preview');

        };
        const buttonShowMore = previewElemToOpen.querySelector('[js-handler*="extend_preview_mobile"]');

        if (buttonShowMore) {
            buttonShowMore.removeEventListener('click', showMoreClickHandler);
            buttonShowMore.addEventListener('click', showMoreClickHandler);
        }
        setupFullDocumentFunctionality(previewElemToOpen);
        setupMiniPopup(previewElemToOpen);
        setupNotImplementedAlert(previewElemToOpen);
        setupTableOfContent(previewElemToOpen);
        setupPreview(previewElemToOpen, docId);
        setupAttachmentDownloadHandler(previewElemToOpen);
        setupEbookAttachmentDownloadHandler(previewElemToOpen);
        updatePrices(previewElemToOpen, docId);
        watchlistAddAllButtons();
        setupSendEmailFunctionality(previewElemToOpen, docId);

        renderVideo();
    } catch (error) {
        console.log(error);
    }
};

const getViewType = (el) => {
    const {page} = window.sharedState;
    const {mediaType} = el.dataset;
    const isVideoType = mediaType === MediaType.VIDEOS;
    const isEBookType = mediaType === MediaType.EBOOK;
    if (!!page && !!mediaType) {
        if (isVideoType || page === "searchResult") {
            return "document";
        } else if (isEBookType || page === "browse")
            return "source";
    }
};


export const fetchRemoteAgencyDocument = function (button, orderDataId, token, params) {

    const download = function (blobData) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        if (!isIOSDevice()) {
            link.target = "_blank";
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fetchStream = function (url, button) {
        button.disabled = true;
        axios.get(url, {
            responseType: "blob"
        }).then(response => {
            download(response.data);
        }).finally(() => {
            button.removeAttribute("disabled");
            setupRemoteAgencyDocDownloadButton(button.parentElement);
        });
    };

    if (!!params?.resultCode && params.resultCode === "REDIRECT_DOWNLOAD_REMOTE_AGENCIES") {
        fetchStream(params?.redirectUrl, button);
        setupRemoteAgencyDocDownloadButton(button.parentElement);
        sessionStorage.setItem('attachmentButton', '');
        return;
    }

    sessionStorage.setItem('attachmentButton', button.outerHTML);

    const orderDataIdParam = orderDataId ? {"orderDataId": orderDataId} : {};
    const captchaParam = token ? {"gRecaptchaResponse": token} : {};
    const fullParams = {
        ...orderDataIdParam,
        ...captchaParam,
        ...params,
        companyName: button.dataset.companyName
    };

    axios({
        method: "POST",
        url: "/remoteAgencies/orderCompanyInfo/" + button.dataset.remoteAgencyDocId,
        data: qs.stringify(fullParams),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        const jsonData = response.data;
        if (jsonData.code === "REDIRECT_DOWNLOAD_REMOTE_AGENCIES") {
            fetchStream(jsonData.downloadAttachmentModel?.redirectUrl, button);
            setupRemoteAgencyDocDownloadButton(button.parentElement);
            sessionStorage.setItem('attachmentButton', '');
            return;
        }
        window.retryOrder = function (orderDataId, token, params) {
            closeTransparentPopupModal();
            fetchRemoteAgencyDocument(button, orderDataId, token, params);
        };
        setupPaymentFormComponent(jsonData, button.dataset.remoteAgencyDocId, PaymentMode.remoteAgency).then();
    });
};


export const fetchMySources = async (dbShortcut = null) => {
    if (window.sharedState.loginStatus.loggedIn) {
        try {
            eventBus.$emit("my-sources-loader", true)
            const {data} = await axios.get("/api/mySources/all", {
                params: {dbShortcut}
            })
            if (!dbShortcut)
                window.sharedState.mySources = data.reverse();
            return data
        } catch (e) {
            console.log(e);
        } finally {
            eventBus.$emit("my-sources-loader", false)
        }
    } else {
        eventBus.$emit("my-sources-loader", false)
    }
};
export const fetchWatchLists = async () => {
    if (window.sharedState.loginStatus.loggedIn) {
        try {
            const {data} = await axios.get("/api/watchlists/all", {});
            return data;
        } catch (e) {
            console.log(e);
        }
    }
}

const checkLoginStatus = () => {
    if (!window.sharedState.loginStatus.loggedIn) return null;
};

export const fetchDocumentsByIds = async (docIds) => {
    const { data: docs } = await axios.get(`/api/bookmarks/docsById?docIds=${docIds}` );
    if (!docs || docs.length === 0) return [];

    const uniqueData = Array.from(new Set(docs.map(item => JSON.stringify(item))))
        .map(item => JSON.parse(item)).filter(i => i);

    const preparedDocuments = uniqueData.map(item => ({
        database: item.database,
        documentId: item.legacyDocId,
        priceCategory: item.priceCategory,
    }));

    return { uniqueData, preparedDocuments };
};

export const extendDocumentsWithPrices = (uniqueData, preparedDocuments) => {
    return new Promise((resolve, reject) => {
        getDocumentPricesAsync(preparedDocuments, (docWithPrices) => {
            try {
                const extendedDocuments = uniqueData.map(item => ({
                    ...item,
                    ...docWithPrices[item.legacyDocId],
                    displayPrice: !!docWithPrices[item.legacyDocId],
                }));
                resolve(extendedDocuments);
            } catch (error) {
                reject(error);
            }
        });
    });
};

export const fetchBookmarksFromApi = async (params) => {
    checkLoginStatus();
    try {
        const { data } = await axios.get("/api/watchlist_bookmarks/docIds", { params });
        if (!data || data.length === 0) return [];
        return data;
    } catch (error) {
        console.error('Error fetching bookmarks:', error);
        throw error;
    }
};

export const updateDocumentPrices = async (docIds, items = []) => {
    if (docIds && docIds.length > 0) {
        const { uniqueData, preparedDocuments } = await fetchDocumentsByIds(docIds);
        const bookmarks = await extendDocumentsWithPrices(uniqueData, preparedDocuments);
        return items.map(item => {
            const matchingDoc = bookmarks.find(doc => doc.documentId === item.bookmarkedItem);
            if (matchingDoc) {
                let extendedBookmark = {
                    ...matchingDoc,
                    bookmarkId: item.id,
                };
                if (item.creationDate) {
                    extendedBookmark = {
                        ...extendedBookmark,
                        creationDate: formatDate(item.creationDate)
                    };
                }
                if (item.itemDate) {
                    extendedBookmark = {
                        ...extendedBookmark,
                        itemDate: formatDate(item.itemDate)
                    };
                }
                return extendedBookmark;
            }
            return null;
        }).filter(Boolean);
    }
    return [];
}

export const fetchDocumentHistoryFromApi = async (params) => {
    checkLoginStatus();
    try {
        const { data } = await axios.get("/api/docRef/documentHistoryItems", { params });
        if (!data || data.length === 0) return [];
        return data;
    } catch (error) {
        console.error('Error fetching document history:', error);
        throw error;
    }
};


export const addNewBookmarkListApi = async (listName) => {
    if (window.sharedState.loginStatus.loggedIn) {
        try {
            const { data } = await axios.post("/api/bookmark/createList", {
                name: listName
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }
}

export const deleteBookmarkListApi = async (bookmarkListsIds) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const idsParam = bookmarkListsIds.join(',');
        const { data } = await axios.delete(`/api/bookmark/deleteLists?ids=${idsParam}`);
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const clearBookmarkListApi = async (bookmarkListsIds) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const idsParam = bookmarkListsIds.join(',');
        const { data } = await axios.put(`/api/bookmark/clearLists?ids=${idsParam}`);
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const activateBookmarkListApi = async (bookmarkListId, isActive) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const { data } = await axios.put(`/api/bookmarkList/activate/${bookmarkListId}`, null, {
            params: {
                active: isActive
            }
        });
        return data;
    } catch (e) {
        console.error('Error activating bookmark list:', e);
        throw e;
    }
};

export const updateBookmarkListApi = async (id, name) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const { data } = await axios.put(`/api/bookmark/updateList/${id}`, null, {
            params: {
                name: name
            },
        });
        return data;
    } catch (error) {
        console.error("Error updating bookmark list:", error);
        throw error;
    }
};

export const deleteBookmarks = (ids) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return axios({
            method: 'delete',
            url: `/api/watchlists/deleteDocuments?bookmarkIds=${ids}`
        })
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const addBookmarks = (selectedItems, documents) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return axios({
            method: 'post',
            url: "/api/watchlists/addDocuments",
            data: {
                "selectedWatchlistIds": selectedItems.filter(item => item).map(item => item.toString()),
                "documents": documents
            },
        })
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const sendBookmarkListEmailApi = async (emailSend) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        const { data } = await axios.post('/api/sendBookmarkListEmail', emailSend);
        return data;
    } catch (error) {
        console.error('Error sending bookmark list email:', error);
        throw error;
    }
};

export const deleteDocumentHistory = (ids) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return axios({method: 'delete', url: `/api/docRef?ids=${ids}`});
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const fetchSearchPurposes = async () => {
    try {
        const { data } = await axios.get("/api/searchpurposes");
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const addSearchPurpose = async (searchPurpose) => {
    try {
        const response = await axios.post('/api/searchpurposes', null, {
            params: {
                searchPurpose: searchPurpose
            }
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return {
            error: true,
            message: e.message
        };
    }
};

export const deleteSearchPurpose = async (searchPurposeId) => {
    try {
        const response = await axios.delete('/api/searchpurposes', {
            params: {
                id: searchPurposeId
            }
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return {
            error: true,
            message: e.message
        };
    }
}

export const fetchUserSearchHistory = async () => {
    try {
        const { data } = await axios.get("/api/searchhistory");
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteSearchHistory = (ids) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return axios({method: 'delete', url: `/api/searchhistory?ids=${ids}`});
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const fetchUserSearchProfiles = async () => {
    try {
        const { data } = await axios.get("/api/searchProfileList");
        return data;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteSearchProfile = async (ids) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return await axios.post('/api/searchProfile/deleteList', [...ids]);
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const updateSearchProfile = async (searchProfile) => {
    if (!window.sharedState.loginStatus.loggedIn) return null;

    try {
        return await axios.put('/api/searchProfile', searchProfile);
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const saveUserAdvancedSearchSettings = async (settings) => {
    try {
        return await axios.post('/api/setSearchFilterDisplaySettings', {...settings});
    } catch (e) {
        console.log(e);
        return {
            error: true,
            message: e.message
        };
    }
};

export const saveUserSearchResultSettings = async (settings) => {
    try {
        return await axios.post('/api/setSearchResultSettings', {...settings});
    } catch (e) {
        console.log(e);
        return {
            error: true,
            message: e.message
        };
    }
};

export const saveUserBookmarksResultSettings = async (settings) => {
    try {
        return await axios.post('/api/setBookmarkListSettings', {...settings});
    } catch (e) {
        console.log(e);
        return {
            error: true,
            message: e.message
        };
    }
};

export const clearActivationApi = async () => {
    try {
        const response = await axios.get('/api/clearActivation');
        return response.data;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const resendConfirmationEmailApi = async () => {
    try {
        const response = await axios.post('/api/resendConfirmationEmail');
        return response.data;
    } catch (e) {
        console.log(e);
        throw e;
    }
}




