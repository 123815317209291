<template>
  <form
      v-if="modalData"
      class="modal-overlay"
      @submit.prevent="onPrimaryAction"
      @keydown.esc="onSecondaryAction">
    <div class="modal-wrapper">
      <div class="modal-container__close-icon" @click="onSecondaryAction">
        <ion-icon name="close-outline"></ion-icon>
      </div>
      <div class="modal-container" ref="modalContainer">
        <div class="modal-container__content">
          <div v-if="modalData.title" class="modal-container__title">{{ modalData.title }}</div>
          <div v-if="type === 'input'" class="modal-container__input">
            <text-form-element
                ref="focusMe"
                :element="inputElement"
                :focus="focus"
                :passed-value="inputValue"
                @modified="inputValue = $event"
                @focus="focus = $event"
            ></text-form-element>
          </div>
          <div class="modal-container__content--container">
            <overlay-scrollbars
                :options="{
            scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
            overflowBehavior: { x: 'hidden'}
           }"
            >
              <div class="modal-container__content--list">
                <p v-if="modalData.description" class="modal-container__description" :class="{'standard-color': modalData.standardColor}">{{ modalData.description }}</p>
                <div v-if="$slots.default" class="searchResult__active_filters">
                  <slot></slot>
                </div>
              </div>
            </overlay-scrollbars>
          </div>
        </div>
        <div class="modal-container__divider"></div>
        <div class="modal-container__footer">
          <Button
              v-if="modalData.secondaryButtonText"
              :text="modalData.secondaryButtonText"
              variant="secondary"
              name="secondary"
              :toggle-text="false"
              type="button"
              @click="onSecondaryAction"
          />
          <Button
              v-if="modalData.primaryButtonText"
              :text="modalData.primaryButtonText"
              variant="primary"
              name="primary"
              :toggle-text="false"
              type="submit"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "../../components/styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";

export default {
  components: {
    TextFormElement,
    Button,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    modalData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    initialInputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: this.initialInputValue,
      focus: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.focusMe?.$el?.querySelector('input')?.focus();
    });
  },
  computed: {
    inputElement() {
      return {
        id: 'info_overlay_input',
        fieldType: 'text',
        fieldLabel: this.modalData?.inputPlaceholder || '',
        hideLabel: !this.modalData?.inputPlaceholder };
    },
  },
  methods: {
    onPrimaryAction() {
      if (this.type === "input") {
        this.$emit("primary-action", this.inputValue);
      } else {
        this.$emit("primary-action");
      }
    },
    onSecondaryAction() {
      this.$emit("secondary-action");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-block);
  z-index: 1001;
}

.modal-wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
}

.modal-container__close-icon {
  position: absolute;
  top: -40px;
  background-color: var(--background-color);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--active-shadow);
  cursor: pointer;
  z-index: 1002;
}

.modal-container__close-icon ion-icon {
  font-size: 1.25rem;
  color: var(--foreground-color);
}

.modal-container {
  background-color: var(--background-color);
  box-shadow: var(--active-shadow);
  padding-top: 30px;
  border-radius: 20px;
  max-width: 560px;
}

.modal-container__content {
  padding: 0 30px;
}

.modal-container__content--container {
  max-height: 350px;
}

.modal-container__content--list {
  max-height: 300px;
}

.modal-container__title {
  color: var(--foreground-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.modal-container__description {
  color: var(--shade-dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 21px;
}

.modal-container__description.standard-color {
  color: var(--foreground-color);
}

.modal-container__footer {
  display: flex;
  justify-content: end;
  margin: 0 30px 24px;
  gap: 10px;
}

.modal-container__divider {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.10);
  margin: 15px 0 24px;
}
.modal-container__input {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .modal-container__content {
    padding: 0 10px;
  }
  .modal-container {
    width: 90vw;
    padding: 20px;
    border-radius: 10px;
  }

  .modal-container__close-icon {
    top: -40px;
    width: 28px;
    height: 28px;
  }

  .modal-container__close-icon ion-icon {
    font-size: 1rem;
  }

  .modal-container__title {
    margin-bottom: 15px;
  }

  .modal-container__description {
    margin-bottom: 18px;
  }

  .modal-container__footer {
    margin: 0;
  }
}
@media screen and (max-width: 380px) {
  .modal-container {
    width: 90%;
  }
}
</style>

