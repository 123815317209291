import axios from "axios";
import {API_LOGIN, API_LOGIN_WEB_USER} from "./endpoints";


export const login = async data => {
    return await loginUser(data, API_LOGIN);
}

export const loginWebUser = async data => {
    return await loginUser(data, API_LOGIN_WEB_USER);
}

const loginUser = async (data, url) => {
    try {
        const {data: restData, status} = await axios.post(url, data, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer',
        });
        if (status === 200) {
            handleLoginStatusResponse(restData);
            return restData;
        }
    } catch (e) {
        console.log("authApi: login: error while log in ", e);
    }
    return {};
}


const handleLoginStatusResponse = (loginStatus) => {
    window.sharedState.loginStatus = loginStatus;
    window.sharedState.permissions = loginStatus.permissions || [];
    window.sharedState.defaultUserEmail = loginStatus.email || "";
    sessionStorage.setItem('defaultUserEmail', loginStatus.email || "");
    window.sharedState.userFrontendSettings = loginStatus.userFrontendSettings || {};
    fetch('/login/login', {
        method: 'POST',
    }).then(/*geniosWeb check ignore result - just still doing this for session sharing i.e. getting JSESSIONID*/);
}